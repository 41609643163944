import React, { useState, useRef, useEffect } from 'react';
import AutosuggestInput from './AutosuggestInput';
import BootstrapDatePicker from './BootstrapDatePicker';
import moment from 'moment';
import { useNavigate } from 'react-router';

const SearchForm = ({ searchData, setSearchData }) => {
    const [search, setSearch] = useState({
        keyword: '',
        branch_id: '',
        occupancy_type: searchData.occupancy_type,
        total_adults: 0,
        total_children: 0,
        number_of_seats: 0,
        dateRange: '',
        date: '',
        error: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        if(searchData.occupancy_type === 'Guest')
        {
            setSearch((prev) => ({
                ...prev,
                date: '',
                occupancy_type: 'Guest',
            }));
        }

        if(searchData.occupancy_type === 'Permanent')
        {
            setSearch((prev) => ({
                ...prev,
                total_adults: 0,
                total_children: 0,
                dateRange: '',
                occupancy_type: 'Permanent'
            }));
        }
    }, [searchData.occupancy_type]);

    const increment = (key) => {
        if (key === 'total_adults') {
            setSearch((prev) => ({
                ...prev,
                total_adults: prev['total_adults'] + 1,
                number_of_seats: prev['total_adults'] + 1,
            }));
        } else {
            setSearch((prev) => ({
                ...prev,
                [key]: prev[key] + 1,
            }));
        }
    };

    const decrement = (key) => {
        if (key === 'number_of_seats') {
            setSearch((prev) => ({
                ...prev,
                [key]: prev[key] > prev['total_adults'] ? prev[key] - 1 : prev['total_adults'],
            }));
        } else {
            setSearch((prev) => ({
                ...prev,
                [key]: prev[key] > 0 ? prev[key] - 1 : 0,
            }));
        }
    };

    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const overlayRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const toggleOverlay = () => {
        setIsOverlayOpen((prevState) => !prevState);
    };

    const handleClickOutside = (event) => {
        if (
            overlayRef.current &&
            !overlayRef.current.contains(event.target) &&
            !toggleButtonRef.current.contains(event.target)
        ) {
            setIsOverlayOpen(false);
        }
    };

    const getPlaceholderText = () => {
        if (search.total_adults > 0 || search.total_children > 0 || search.number_of_seats > 0) {
            return [
                search.total_adults > 0 ? `${search.total_adults} Adult${search.total_adults > 1 ? 's' : ''}` : '',
                search.total_children > 0 ? `${search.total_children} Child${search.total_children > 1 ? 'ren' : ''}` : '',
                search.number_of_seats > 0 ? `${search.number_of_seats} Seat${search.number_of_seats > 1 ? 's' : ''}` : ''
            ].filter(Boolean).join(', ');
        }
        return searchData.occupancy_type === 'Permanent' ? "Add Seat" : "Add Guest";
    };

    useEffect(() => {
        if (isOverlayOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOverlayOpen]);

    const handleDateApply = (selectedDate) => {
        if (typeof selectedDate === 'string') {
          setSearch((search) => ({
            ...search,
            date: selectedDate,
            dateRange: '',
          }));
        } else {
          setSearch((search) => ({
            ...search,
            date: '',
            dateRange: [selectedDate.start, selectedDate.end]
          }));
        }
      };

      const handleSearch = () => {
            setSearchData((prevData) => ({
                ...prevData,
                paneltype: 'tabs',
                isVisible: false,
                keyword: search.keyword,
                branch_id: search.branch_id,
                occupancy_type: search.occupancy_type,
                total_adults: searchData.occupancy_type === 'Guest' 
                ? (search.total_adults > 0 ? search.total_adults : ' Add') + ' Guest' 
                : (search.number_of_seats > 0 ? search.number_of_seats : ' Add') + ' Seats',
                date: search.date,
                dateRange: search.dateRange,
            }));

        // const queryParams = new URLSearchParams({
        //     keyword: search.keyword,
        //     branch_id: search.branch_id,
        //     occupancy_type: (search.keyword === '' && search.branch_id === '' && search.date === '' && search.dateRange === '') ? '' : search.occupancy_type,
        //     number_of_seats: search.number_of_seats,
        //     total_adults: search.total_adults,
        //     date: search.date,
        //     dateRange: search.dateRange,
        // });

        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries({
                    keyword: search.keyword,
                    branch_id: search.branch_id,
                    occupancy_type: (search.keyword === '' && search.branch_id === '' && search.date === '' && search.dateRange === '' && search.number_of_seats < 1) ? '' : search.occupancy_type,
                    number_of_seats: search.number_of_seats,
                    total_adults: search.total_adults,
                    date: search.date,
                    dateRange: search.dateRange,
                }).filter(([key, value]) => value !== '' && value !== 0 && value !== null && value !== undefined)
            )
        );
    
        navigate({
            pathname: '/',
            search: `?${queryParams.toString()}`,
        });
    };

    return (
        <>
            <div className={`search-panel container-fluid mt-4 ${searchData.isVisible ? 'visible' : 'hidden'}`}>
                <div className='col-md-11 mx-auto'>
                    <div className="search_form" >
                        <div className="d-flex align-items-center">
                            <div className="flex-fill  pl-0 autosuggest_search_box" style={{'minWidth':'33%'}}>
                                <AutosuggestInput selectedValue={search.branch_id} setSelectedValue={setSearch} />
                            </div>
                            {searchData.occupancy_type === 'Permanent' ? (
                                <>
                                    <div className="border-left mx-2" style={{ height: "30px" }} ></div>
                                    <div className="flex-fill pl-0">
                                        <BootstrapDatePicker
                                            key="permanent"
                                            onDateSelect={handleDateApply}
                                            isRange={false}
                                            initialDate={search.date}
                                            placeholderText={'Check In'}
                                            initialSettings={{
                                                minDate: moment().startOf('day'),
                                            }}
                                            onChange={() => {setSearch((search) => ({...search, date: '', dateRange: ''}));}}
                                        />
                                    </div>
                                    <div className="border-left mx-2" style={{ height: "30px" }}></div>
                                    <div className="flex-fill" style={{ 'position': 'relative', 'width': '310px' }} >
                                        <input type="text"
                                            className="form-control border-0 p-0 pr-5"
                                            placeholder={getPlaceholderText()}
                                            value={getPlaceholderText()}
                                            ref={toggleButtonRef}
                                            onClick={toggleOverlay}
                                        />
                                        <div ref={overlayRef} className={`input_overlay ${isOverlayOpen ? 'open' : ''}`}>
                                            <div className='guest_counter mb-0'>
                                                <div className='pull-left'>
                                                    <strong>Seat</strong>
                                                    <br />
                                                    <span>Minimum 1+</span>
                                                </div>
                                                <div className='input_counter pull-right'>
                                                    <button onClick={() => decrement('number_of_seats')}>-</button>
                                                    <strong className='counter'>{search.number_of_seats}</strong>
                                                    <button onClick={() => increment('number_of_seats')}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="border-left mx-2" style={{ height: "30px" }}></div>
                                    <div className="flex-fill pl-0">
                                        <BootstrapDatePicker
                                            key="guest"
                                            onDateSelect={handleDateApply}
                                            isRange={true}
                                            initialDate={search.dateRange}
                                            placeholderText={'Check In ~ Check Out'}
                                            initialSettings={{
                                                minDate: moment().startOf('day'),
                                            }}
                                            onChange={() => {setSearch((search) => ({...search, date: '', dateRange: ''}));}}
                                        />
                                    </div>
                                    <div className="border-left mx-2" style={{ height: "30px" }}></div>
                                    <div className="flex-fill" style={{ 'position': 'relative', 'width': '310px' }} >
                                        <input type="text"
                                            className="form-control border-0 p-0 pr-5"
                                            placeholder={getPlaceholderText()}
                                            value={getPlaceholderText()}
                                            ref={toggleButtonRef}
                                            onClick={toggleOverlay}
                                        />
                                        <div ref={overlayRef} className={`input_overlay ${isOverlayOpen ? 'open' : ''}`}>
                                            <div className='guest_counter'>
                                                <div className='pull-left'>
                                                    <strong>Adults</strong>
                                                    <br />
                                                    <span>Age 13+</span>
                                                </div>
                                                <div className='input_counter pull-right'>
                                                    <button onClick={() => decrement('total_adults')}>-</button>
                                                    <strong className='counter'>{search.total_adults}</strong>
                                                    <button onClick={() => increment('total_adults')}>+</button>
                                                </div>
                                            </div>
                                            <div className='guest_counter'>
                                                <div className='pull-left'>
                                                    <strong>Children</strong>
                                                    <br />
                                                    <span>Age 0-5</span>
                                                </div>
                                                <div className='input_counter pull-right'>
                                                    <button onClick={() => decrement('total_children')}>-</button>
                                                    <strong className='counter'>{search.total_children}</strong>
                                                    <button onClick={() => increment('total_children')}>+</button>
                                                </div>
                                            </div>
                                            <div className='guest_counter mb-0'>
                                                <div className='pull-left'>
                                                    <strong>Seat</strong>
                                                    <br />
                                                    <span>Minimum 1+</span>
                                                </div>
                                                <div className='input_counter pull-right'>
                                                    <button onClick={() => decrement('number_of_seats')}>-</button>
                                                    <strong className='counter'>{search.number_of_seats}</strong>
                                                    <button onClick={() => increment('number_of_seats')}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="search_submit_btn">
                                <button className="btn btn-danger rounded-circle p-2" type="button" onClick={handleSearch}>
                                    <i className="fa fa-search text-white"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchForm;
