import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import HtmlParser from 'html-react-parser';
import WithRouter from '../../_utility/WithRouter';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BootstrapDatePicker from '../../component/partials/BootstrapDatePicker';
import OwlCarousel from 'react-owl-carousel';
const options = {
    items: 1,
    margin: 20,
    itemsDesktop: [1000, 1],
    itemsDesktopSmall: [979, 1],
    itemsTablet: [768, 1],
    pagination: false,
    navigation: false,
    dots: false,
    navigationText: ["", ""],
    slideSpeed: 1000,
    singleItem: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true
};

const RoomDetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [room, setRoom] = useState([]);
    const [images, setImages] = useState([]);
    const [description, setDescription] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        const room_id = props.params.room_id;

        axios.get('/sanctum/csrf-cookie').then(() => {
            axios.get(`/api/room/${room_id}/details`)
                .then(res => {
                    const data = res.data.data;
                    setRoom(data);
                    setImages(data.images);
                    setDescription(data.description);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching rooms:", error);
                });
        });
    }, [props.params.room_id]);

    const [reserve, setReserve] = useState({
        occupancy_type: '',
        total_adults: 1,
        total_children: 0,
        number_of_seats: 1,
        dateRange: '',
        date: '',
        isOverlayOpen: false,
        error: '',
    });

    const pickerRef = useRef(null);
    const overlayRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const navigate = useNavigate();

    const OccupancyOptions = {
        Both: {
            "": "Occupancy Type",
            Permanent: "Permanent",
            Guest: "Guest",
        },
        Permanent: {
            "": "Occupancy Type",
            Permanent: "Permanent",
        },
        Guest: {
            "": "Occupancy Type",
            Guest: "Guest",
        },
    };

    const increment = (key) => {
        if (key === 'total_adults') {
            setReserve((prev) => ({
                ...prev,
                total_adults: prev['total_adults'] + 1,
                number_of_seats: prev['total_adults'] + 1,
            }));
        } else {
            setReserve((prev) => ({
                ...prev,
                [key]: prev[key] + 1,
            }));
        }
    };

    const decrement = (key) => {
        if (key === 'number_of_seats') {
            setReserve((prev) => ({
                ...prev,
                [key]: prev[key] > prev['total_adults'] ? prev[key] - 1 : prev['total_adults'],
            }));
        } else {
            setReserve((prev) => ({
                ...prev,
                [key]: prev[key] > 0 ? prev[key] - 1 : 0,
            }));
        }
    };

    const updateState = (key, value) => {
        setReserve((prevState) => ({
            ...prevState,
            [key]: value,
        }));

        if (key === 'occupancy_type' && value) {
            setReserve((prev) => ({
                ...prev,
                date: '',
                dateRange: '',
                error: '',
            }));
        }
        if (key === 'date' && value && reserve.occupancy_type === 'Permanent') {
            setReserve((prev) => ({
                ...prev,
                error: '',
            }));
        }
        if (key === 'dateRange' && value && reserve.occupancy_type !== 'Permanent') {
            setReserve((prev) => ({
                ...prev,
                error: '',
            }));
        }
    };


    const toggleOverlay = () => {
        setReserve((prev) => ({
            ...prev,
            isOverlayOpen: !prev.isOverlayOpen,
        }));
    };

    const handleClickOutside = (event) => {
        if (
            overlayRef.current &&
            !overlayRef.current.contains(event.target) &&
            !toggleButtonRef.current.contains(event.target)
        ) {
            setReserve((prev) => ({
                ...prev,
                isOverlayOpen: false,
            }));
        }
    };

    useEffect(() => {
        if (reserve.isOverlayOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [reserve.isOverlayOpen]);

    const handleReserveClick = () => {
        const room_id = props.params.room_id;
        updateState('error', '');

        if (!reserve.occupancy_type) {
            updateState('error', 'Please select an occupancy type.');
            return;
        }
        if (reserve.occupancy_type === 'Permanent' && !reserve.date) {
            updateState('error', 'Please select a check-in date.');
            return;
        }
        if (reserve.occupancy_type !== 'Permanent' && (!reserve.dateRange[0] || !reserve.dateRange[1])) {
            updateState('error', 'Please select both check-in and check-out dates.');
            return;
        }

        navigate(`/room/${room_id}/booking`, {
            state: {
                branch_id: room?.apartment?.branch_id || '',
                apartment_id: room?.apartment?.id || '',
                occupancy_type: reserve.occupancy_type,
                total_adults: reserve.total_adults,
                total_children: reserve.total_children,
                number_of_seats: reserve.number_of_seats,
                date: reserve.date,
                dateRange: reserve.dateRange,
            },
        });
    };

    const handleDateApply = (date) => {
		setReserve((bookingInfo) => ({
		  ...bookingInfo,
		  dateRange: '',
		  date: date
		}));
	  };
	  
	  const handleDateRangeSelect = ({ start, end }) => {
		setReserve((bookingInfo) => ({
		  ...bookingInfo,
		  date: '',
		  dateRange: [start, end]
		}));
	  };

    return (
        <>
            <section>
                <div className="container-fluid pad-bot-40 mt-5">
                    <div className="col-sm-11 mx-auto">
                        <div className="to-ho-hotel">
                            <div className="row">
                                <div className='col-sm-12'>
                                    <div className="modal fade" id="galeryModal" tabindex="-1" role="dialog" aria-labelledby="galeryModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="galeryModalLabel">All Photos</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    {room.featured_image && room.featured_image.length > 0 ? (
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                            className="img-fluid"
                                                        />
                                                    ) : (<></>)}
                                                    {images && images.length > 0 ? (
                                                        images.map((image, index) => (
                                                            <img
                                                                src={image.data_url}
                                                                alt={room.title}
                                                                className="img-fluid"
                                                            />
                                                        ))
                                                    ) : (<></>)}
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {room.featured_image && room.featured_image.length > 0 ? (
                                        <div className="row gallery_images_wrap">
                                            <div className="col-md-6 room_gallery_large p-0">
                                                {room.featured_image && room.featured_image.length > 0 ? (
                                                    <img
                                                        src={room.featured_image[0].data_url}
                                                        alt={room.title}
                                                        className='d-none d-lg-block d-md-block'
                                                    />
                                                ) : (
                                                    <>
                                                        <img src="/assets/images/room/4.jpg" className='d-none d-lg-block d-md-block' alt="Gallery 2" />
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-6 room_gallery_small p-0">
                                                {images && images.length > 0 ? (
                                                    images.map((image, index) => (
                                                        <img
                                                            src={image.data_url}
                                                            alt={room.title}
                                                        />
                                                    ))
                                                ) : (
                                                    <>
                                                        <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                        <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                        <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                        <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                    </>
                                                )}
                                                <a href='javascripts:void' className='btn btn-light border border-dark gallery-grid-btn' data-toggle="modal" data-target="#galeryModal"><i className="fa fa-th" aria-hidden="true"></i> Show all photos</a>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-6 d-none d-lg-block d-md-block'>
                                                    <div class="ph-item p-0 border-0">
                                                        <div class="ph-picture" style={{ 'height': '470px' }}></div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 pl-0'>
                                                    <div class="ph-item p-0 border-0" style={{ 'display': 'grid', 'grid-template-columns': 'repeat(2, 1fr)', 'gap': '10px' }}>
                                                        <div class="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                        <div class="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                        <div class="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                        <div class="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>

                            {room && room.title ? (
                                <>
                                    <div className="hom-com container pt-5 pb-1">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className='form-group mb-5'>
                                                    <h2>{room.title}</h2>
                                                    <span>{room.room_type} . {room.max_occupancy} Seat . Free Internet</span>
                                                </div>

                                                <OwlCarousel className="owl-carousel mb-5 owl-theme" {...options}>
                                                    <div className="media customer_review">
                                                        <img src="//c2.staticflickr.com/8/7310/buddyicons/24846422@N06_r.jpg" className="align-self-center  mr-3" alt="..." />
                                                        <div className="media-body">
                                                            <h5 className="mt-0">Gabriel Elijah</h5>
                                                            <div className='review_rating mb-1'>
                                                                <span>4.9 </span>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-o" aria-hidden="true"></i>
                                                            </div>
                                                            <p>Too much good experience with hospitality, cleanliness, facility and privacy and good value for money... To keep mind relaxing... Keep it up...</p>
                                                        </div>
                                                    </div>
                                                    <div className="media customer_review">
                                                        <img src="https://i.postimg.cc/ydBjdm20/michael-dam-m-EZ3-Po-FGs-k-unsplash-1.jpg" className="align-self-center  mr-3" alt="..." />
                                                        <div className="media-body">
                                                            <h5 className="mt-0">Diana</h5>
                                                            <div className='review_rating mb-1'>
                                                                <span>4.9 </span>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-o" aria-hidden="true"></i>
                                                            </div>
                                                            <p>Too much good experience with hospitality, cleanliness, facility and privacy and good value for money... To keep mind relaxing... Keep it up...</p>
                                                        </div>
                                                    </div>
                                                    <div className="media customer_review">
                                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/451270/profile/profile-80.jpg" className="align-self-center  mr-3" alt="..." />
                                                        <div className="media-body">
                                                            <h5 className="mt-0">Kevin Jack</h5>
                                                            <div className='review_rating mb-1'>
                                                                <span>4.9 </span>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-o" aria-hidden="true"></i>
                                                            </div>
                                                            <p>Too much good experience with hospitality, cleanliness, facility and privacy and good value for money... To keep mind relaxing... Keep it up...</p>
                                                        </div>
                                                    </div>
                                                </OwlCarousel>
                                                <div className="hp-section">
                                                    <div className="hp-amini detai-2p">
                                                        {HtmlParser(description)}
                                                    </div>
                                                </div>
                                                <div className="hp-section mb-3">
                                                    <div className="hp-sub-tit">
                                                        <h4><span>Aminitiese</span> Room</h4>
                                                        <p>Aliquam id tempor sem. Cras molestie risus et lobortis congue. Donec id est consectetur, cursus tellus at, mattis lacus.</p>
                                                    </div>
                                                    <div className="room-amini overflow-hidden">
                                                        <ul className='overflow-hidden p-0'>
                                                            {room.amenities && room.amenities.length > 0 ? (
                                                                room.amenities.map((amenity, index) => (
                                                                    <li key={index}>
                                                                        <img
                                                                            src={amenity.thumbnail || "/assets/images/icon/a2.png"}
                                                                            alt={amenity.name}
                                                                        />
                                                                        {amenity.name}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="sidebar_widget">
                                                    <div className='sidebar_booking_widget'>
                                                        <p className='room_price'>
                                                            {reserve.occupancy_type === 'Permanent' ? (
                                                                <>
                                                                    {room.price_per_month ? (
                                                                        <>
                                                                            <strong>৳{room.price_per_month}</strong>
                                                                            <span className='text-muted'> per month</span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {room.price_per_night ? (
                                                                        <>
                                                                            <strong>৳{room.price_per_night}</strong>
                                                                            <span className='text-muted'> per night</span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                        <div className='booking_widget_form mb-3'>
                                                            <div className="form-group mb-0 border-bottom">
                                                                <select
                                                                    name="occupancy_type"
                                                                    className="form-control form-control-lg"
                                                                    style={{ "border-radius": "10px 10px 0 0", "border": "none", "font-size": "16px", "color": "#111", "font-weight": "500", "height": "48px" }}
                                                                    onChange={(e) => updateState('occupancy_type', e.target.value)}
                                                                >   {OccupancyOptions[room.occupancy_type] &&
                                                                    Object.entries(OccupancyOptions[room.occupancy_type]).map(([value, label]) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="dates" ref={pickerRef}>
                                                                {reserve.occupancy_type === 'Permanent' ? (
                                                                    <>
                                                                        <BootstrapDatePicker
                                                                            key="permanent"
                                                                            onDateSelect={handleDateApply} 
                                                                            isRange={false}
                                                                            initialDate={reserve.date}
                                                                            placeholderText={'Check In'}
                                                                            initialSettings={{
                                                                                minDate: moment().startOf('day'),
                                                                            }}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <BootstrapDatePicker
                                                                            key="guest"
                                                                            onDateSelect={handleDateRangeSelect} 
                                                                            isRange={true}
                                                                            initialDate={reserve.dateRange}
                                                                            placeholderText={'Check In ~ Check Out'}
                                                                            initialSettings={{
                                                                                minDate: moment().startOf('day'),
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className='guest'>
                                                                <div ref={toggleButtonRef} onClick={toggleOverlay}>
                                                                    <p><strong>{reserve.occupancy_type === 'Permanent' ? "Seat" : "Guest"}</strong></p>
                                                                    <p>
                                                                        {reserve.occupancy_type === 'Permanent' ? (
                                                                            <>
                                                                                {reserve.number_of_seats > 0 && <span>{reserve.number_of_seats} Seat{reserve.number_of_seats > 1 ? 's' : ''} </span>}
                                                                                {(reserve.number_of_seats === 0) && (
                                                                                    <span>Add Seat</span>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {reserve.total_adults > 0 && <span>{reserve.total_adults} Adult{reserve.total_adults > 1 ? 's' : ''} </span>}
                                                                                {reserve.total_children > 0 && <span>{reserve.total_children} Child{reserve.total_children > 1 ? 'ren' : ''} </span>}
                                                                                {reserve.number_of_seats > 0 && <span>{reserve.number_of_seats} Seat{reserve.number_of_seats > 1 ? 's' : ''} </span>}

                                                                                {(reserve.total_adults === 0 && reserve.total_children === 0 && reserve.number_of_seats === 0) && (
                                                                                    <span>Add Guest</span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div ref={overlayRef} className={`input_overlay ${reserve.isOverlayOpen ? 'open' : ''}`}>
                                                                    {reserve.occupancy_type === 'Permanent' ? (
                                                                        <>
                                                                            <div className='guest_counter mb-0'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Seat</strong>
                                                                                    <br />
                                                                                    <span>Minimum 1+</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('number_of_seats')}>-</button>
                                                                                    <strong className='counter'>{reserve.number_of_seats}</strong>
                                                                                    <button onClick={() => increment('number_of_seats')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className='guest_counter'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Adults</strong>
                                                                                    <br />
                                                                                    <span>Age 13+</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('total_adults')}>-</button>
                                                                                    <strong className='counter'>{reserve.total_adults}</strong>
                                                                                    <button onClick={() => increment('total_adults')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='guest_counter'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Children</strong>
                                                                                    <br />
                                                                                    <span>Age 0-5</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('total_children')}>-</button>
                                                                                    <strong className='counter'>{reserve.total_children}</strong>
                                                                                    <button onClick={() => increment('total_children')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='guest_counter mb-0'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Seat</strong>
                                                                                    <br />
                                                                                    <span>Minimum 1+</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('number_of_seats')}>-</button>
                                                                                    <strong className='counter'>{reserve.number_of_seats}</strong>
                                                                                    <button onClick={() => increment('number_of_seats')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {reserve.error && <p className="text-danger text-center">{reserve.error}</p>}
                                                        <button type='submit' className='btn btn-lg btn-block btn-success mb-4' onClick={handleReserveClick}>Reserve</button>

                                                        <p className='text-center mb-0'>You won't be charged yet</p>
                                                    </div>
                                                </div>

                                                <div className="sidebar_widget">
                                                    <div className="hp-call-in text-center">
                                                        <img src="/younic-logo.png" alt="" />
                                                        <h3><span>Check Availability. Call us!</span></h3>
                                                        <strong className='mb-0'>+88 01325-05 19 15</strong> <br />
                                                        <strong>+88 01711-70 47 29</strong> <br /><br />
                                                        <small>We are available 24/7 Monday to Sunday</small>
                                                        <a href="#">Call Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="hom-com container pt-5 pb-1">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className='form-group mb-0'>
                                                    <div class="ph-item p-0 border-0">
                                                        <div class="ph-col-12 p-0">
                                                            <div class="ph-row">
                                                                <div class="ph-col-12 big"></div>
                                                                <div class="ph-col-6"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ph-item border-0 p-0">
                                                    <div class="ph-col-2">
                                                        <div class="ph-avatar"></div>
                                                    </div>
                                                    <div>
                                                        <div class="ph-row">
                                                            <div class="ph-col-6 big"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-2"></div>
                                                            <div class="ph-col-10 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ph-item border-0 p-0">
                                                    <div class="ph-col-12">
                                                        <div class="ph-picture"></div>
                                                        <div class="ph-row">
                                                            <div class="ph-col-10 big"></div>
                                                            <div class="ph-col-2 empty big"></div>
                                                            <div class="ph-col-4"></div>
                                                            <div class="ph-col-8 empty"></div>
                                                            <div class="ph-col-6"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                        </div>
                                                    </div>

                                                    <div class="ph-col-4">
                                                        <div class="ph-picture"></div>
                                                    </div>

                                                    <div>
                                                        <div class="ph-row">
                                                            <div class="ph-col-6"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-2"></div>
                                                            <div class="ph-col-10 empty"></div>
                                                            <div class="ph-col-8"></div>
                                                            <div class="ph-col-4 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-12">
                                                        <div class="ph-row">
                                                            <div class="ph-col-10 big"></div>
                                                            <div class="ph-col-2 empty big"></div>
                                                            <div class="ph-col-4"></div>
                                                            <div class="ph-col-8 empty"></div>
                                                            <div class="ph-col-6"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-10 big"></div>
                                                            <div class="ph-col-2 empty big"></div>
                                                            <div class="ph-col-4"></div>
                                                            <div class="ph-col-8 empty"></div>
                                                            <div class="ph-col-6"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-10 big"></div>
                                                            <div class="ph-col-2 empty big"></div>
                                                            <div class="ph-col-4"></div>
                                                            <div class="ph-col-8 empty"></div>
                                                            <div class="ph-col-6"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ph-item border-0 p-0">
                                                    <div class="ph-col-12">
                                                        <div class="ph-row">
                                                            <div class="ph-col-6 big"></div>
                                                            <div class="ph-col-6 empty"></div>
                                                            <div class="ph-col-12"></div>
                                                            <div class="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ph-item border-0 p-0">
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div class="ph-col-3">
                                                        <div class="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div class="ph-row mt-2">
                                                            <div class="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="sidebar_widget">
                                                    <div class="ph-item border-0 p-0 m-0">
                                                        <div class="ph-col-12 p-0 m-0">
                                                            <div class="ph-row">
                                                                <div class="ph-col-6 big"></div>
                                                                <div class="ph-col-6 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sidebar_widget">
                                                    <div class="ph-item border-0 p-0 m-0">
                                                        <div class="ph-col-12 p-0 m-0">
                                                            <div class="ph-avatar" style={{ 'width': '100px', 'margin': '0 auto' }}></div>
                                                            <div class="ph-row mt-3">
                                                                <div class="ph-col-6 big"></div>
                                                                <div class="ph-col-6 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid pad-bot-40'>
                    <div className="col-sm-8 mx-auto pl-5 pr-5">
                        <div className="contact-map" style={{ 'height': '500px' }}>
                            <iframe title='Younic Home' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7303.948859349035!2d90.3701136949866!3d23.748291235068937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf00724da8b9%3A0x82cd207efebe07ac!2sYounic%20Home!5e0!3m2!1sen!2sbd!4v1731401256947!5m2!1sen!2sbd" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq_section">
                <div className="container">
                    <div className="faq-title">
                        <h2>F.A.Q</h2>
                        <p>Dolor sit amet consectetur adipiscing elit</p>
                    </div>
                    <ul className="faq-list">
                        <li data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                            <a data-toggle="collapse" className="collapsed" href="#faq1" aria-expanded="false">Non consectetur a erat nam at lectus urna duis? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq1" className="collapse" data-parent=".faq-list">
                                <p>
                                    Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="200" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq2" className="collapsed">Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq2" className="collapse" data-parent=".faq-list">
                                <p>
                                    Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="300" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq3" className="collapsed">Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq3" className="collapse" data-parent=".faq-list">
                                <p>
                                    Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="400" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq4" className="collapsed">Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq4" className="collapse" data-parent=".faq-list">
                                <p>
                                    Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="500" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq5" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq5" className="collapse" data-parent=".faq-list">
                                <p>
                                    Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="600" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq6" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq6" className="collapse" data-parent=".faq-list">
                                <p>
                                    Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Nibh tellus molestie nunc non blandit massa enim nec.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

        </>
    );
}

export default WithRouter(RoomDetails);
