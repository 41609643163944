import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useBranches from '../../data/useBranches';
import SessionHelper from '../../session/SessionHelper';
import { useModal } from '../../_context/ModalContext';

const Footer = () => {
    const { openModal } = useModal();
    const branches = useBranches();
    const navigate = useNavigate();

    const handleNavigateHome = (e) => {
        e.preventDefault(); 
        const queryParams = new URLSearchParams({
            occupancy_type: 'Guest',
        });

        navigate({
            pathname: '/',
            search: `?${queryParams.toString()}`,
        });
    };

        return (
            <>
                <footer className="site-footer clearfix">
                    <div className="sidebar-container">
                        <div className="sidebar-inner">
                            <div className="widget-area clearfix">
                                <div className="widget widget_azh_widget">
                                    <div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-3 foot-logo"> <img src="/assets/images/logo1.png" alt="logo" />
                                                    <p className="hasimg">A dormitory is an establishment that provides paid lodging on a short-term and long-term basis.</p>
                                                    <p className="hasimg">Facilities provided may range from a modest-quality.</p>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <h4>Support &amp; Help</h4>
                                                    <ul className="d-flex">
                                                        <div>
                                                            <li><Link to='/'>Home</Link></li>
                                                            <li><Link to='/about-us'>About Us</Link></li>
                                                            <li><Link to='/services'>Services</Link></li>
                                                            <li><Link to='/contact-us'>Contact Us</Link></li>
                                                        </div>
                                                        <div>
                                                            <li><Link to='/events'>Events</Link></li>
                                                            {SessionHelper.GetAuthSession() && (
                                                                <li><Link to='/dashboard'>My Account</Link></li>
                                                            )}
                                                            
                                                            {!SessionHelper.GetAuthSession() && (
                                                                <>
                                                                    <li><Link onClick={() => openModal('register')}>Register</Link></li>
                                                                    <li><Link onClick={() => openModal('login')}>Log In</Link></li>
                                                                </>
                                                            )}
                                                        </div>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <h4>Branches</h4>
                                                    <ul >
                                                        {branches && branches.length > 0 ? (
                                                            branches.map((branch, index) => (
                                                                <li key={index}>
                                                                    <a href="javascripts:void(0)">
                                                                        {branch.name}
                                                                    </a>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li>No branches available.</li> // Message when no branches are present
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <h4>Address</h4>
                                                    <p>House-20, Road-5, Sector-5, Uttara, Dhaka-1230.</p>
                                                    <p> 
                                                        <span className="foot-phone float-left mr-3">Helpline: </span> 
                                                        <span className="foot-phone float-left">
                                                            <p className='mb-1'>+88 01325-05 19 15</p>
                                                            <p>+88 01711-70 47 29</p>
                                                        </span> 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="foot-sec2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-3">
                                                    <h4>Payment Options</h4>
                                                    <p className="hasimg"> <img src="/assets/images/payment.png" alt="payment" /> </p>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <h4>Subscribe Now</h4>
                                                    <form>
                                                        <ul className="foot-subsc">
                                                            <li>
                                                                <input type="text" placeholder="Enter your email id" />
                                                            </li>
                                                            <li>
                                                                <input type="submit" value="submit" />
                                                            </li>
                                                        </ul>
                                                    </form>
                                                </div>
                                                <div className="col-sm-12 col-md-5 foot-social">
                                                    <h4>Follow with us</h4>
                                                    <p>Feel at home on a stress-free budget</p>
                                                    <ul>
                                                        <li><a href="https://www.facebook.com/YounicHome" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://bd.linkedin.com/company/younic-home" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://www.instagram.com/younichome" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://www.youtube.com/@younichome2023" target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://www.tiktok.com/@younic_home" target='_blank' title='Tiktok'><i className="fa fa-tiktok" aria-hidden="true"></i></a> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="copy">
                    <div className="container">
                        <p>Copyright ©2024 Younic Hostel. All Rights Reserved. Develop by Younic Dev.</p>
                    </div>
                </div>

                <div className="hom-footer-section">
					<div className="container">
						<div className="row">
                            <div className='col-sm-12'>
                                <div className="foot-com foot-1">
                                    <ul>
                                        <li><a href="https://www.facebook.com/YounicHome" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> </li>
                                        <li><a href="https://bd.linkedin.com/company/younic-home" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a> </li>
                                        <li><a href="https://www.instagram.com/younichome" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a> </li>
                                        <li><a href="https://www.youtube.com/@younichome2023" target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i></a> </li>
                                        <li><a href="https://www.tiktok.com/@younic_home" target='_blank' title='Tiktok'><i className="fa fa-tiktok" aria-hidden="true"></i></a> </li>
                                    </ul>
                                </div>
                                <div className="foot-com foot-2">
                                    <h5>Helpline: +88 01325-05 19 15, +88 01711-70 47 29</h5> </div>
                                <div className="foot-com foot-3">
                                <a href='#'
                                className="waves-effect waves-light"
                                onClick={handleNavigateHome}
                            >
                                Guest Booking
                            </a>
                                </div>
                            </div>
						</div>
					</div>
				</div>
            </>
        );
}

export default Footer;
